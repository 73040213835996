import 'core-js/es';
import 'core-js/es/reflect';
import 'core-js/features/reflect';
import 'intl';
import 'intl/locale-data/jsonp/en';
require('rxjs');

import 'zone.js/dist/zone';
import 'zone.js/dist/zone-patch-rxjs';

if (process.env.ENV === 'production') {
  // Production
} else {
  // Development and test
  Error.stackTraceLimit = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}

// Resolves issue with browsers that do not support Element.matches
// (without this, IE 11 fails to remove elements that should no longer be visible from the DOM)
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.webkitMatchesSelector ||
                              (Element.prototype as any).msMatchesSelector;
}

